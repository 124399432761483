// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-blocked-en-js": () => import("./../../../src/pages/account-blocked.en.js" /* webpackChunkName: "component---src-pages-account-blocked-en-js" */),
  "component---src-pages-account-blocked-ja-js": () => import("./../../../src/pages/account-blocked.ja.js" /* webpackChunkName: "component---src-pages-account-blocked-ja-js" */),
  "component---src-pages-account-blocked-zh-js": () => import("./../../../src/pages/account-blocked.zh.js" /* webpackChunkName: "component---src-pages-account-blocked-zh-js" */),
  "component---src-pages-activation-en-js": () => import("./../../../src/pages/activation.en.js" /* webpackChunkName: "component---src-pages-activation-en-js" */),
  "component---src-pages-activation-ja-js": () => import("./../../../src/pages/activation.ja.js" /* webpackChunkName: "component---src-pages-activation-ja-js" */),
  "component---src-pages-activation-success-en-js": () => import("./../../../src/pages/activation-success.en.js" /* webpackChunkName: "component---src-pages-activation-success-en-js" */),
  "component---src-pages-activation-success-ja-js": () => import("./../../../src/pages/activation-success.ja.js" /* webpackChunkName: "component---src-pages-activation-success-ja-js" */),
  "component---src-pages-activation-success-zh-js": () => import("./../../../src/pages/activation-success.zh.js" /* webpackChunkName: "component---src-pages-activation-success-zh-js" */),
  "component---src-pages-activation-zh-js": () => import("./../../../src/pages/activation.zh.js" /* webpackChunkName: "component---src-pages-activation-zh-js" */),
  "component---src-pages-callback-en-js": () => import("./../../../src/pages/callback.en.js" /* webpackChunkName: "component---src-pages-callback-en-js" */),
  "component---src-pages-callback-ja-js": () => import("./../../../src/pages/callback.ja.js" /* webpackChunkName: "component---src-pages-callback-ja-js" */),
  "component---src-pages-callback-zh-js": () => import("./../../../src/pages/callback.zh.js" /* webpackChunkName: "component---src-pages-callback-zh-js" */),
  "component---src-pages-complete-profile-en-js": () => import("./../../../src/pages/complete-profile.en.js" /* webpackChunkName: "component---src-pages-complete-profile-en-js" */),
  "component---src-pages-complete-profile-ja-js": () => import("./../../../src/pages/complete-profile.ja.js" /* webpackChunkName: "component---src-pages-complete-profile-ja-js" */),
  "component---src-pages-complete-profile-zh-js": () => import("./../../../src/pages/complete-profile.zh.js" /* webpackChunkName: "component---src-pages-complete-profile-zh-js" */),
  "component---src-pages-create-new-password-en-js": () => import("./../../../src/pages/create-new-password.en.js" /* webpackChunkName: "component---src-pages-create-new-password-en-js" */),
  "component---src-pages-create-new-password-ja-js": () => import("./../../../src/pages/create-new-password.ja.js" /* webpackChunkName: "component---src-pages-create-new-password-ja-js" */),
  "component---src-pages-create-new-password-zh-js": () => import("./../../../src/pages/create-new-password.zh.js" /* webpackChunkName: "component---src-pages-create-new-password-zh-js" */),
  "component---src-pages-faq-en-js": () => import("./../../../src/pages/faq.en.js" /* webpackChunkName: "component---src-pages-faq-en-js" */),
  "component---src-pages-faq-ja-js": () => import("./../../../src/pages/faq.ja.js" /* webpackChunkName: "component---src-pages-faq-ja-js" */),
  "component---src-pages-faq-zh-js": () => import("./../../../src/pages/faq.zh.js" /* webpackChunkName: "component---src-pages-faq-zh-js" */),
  "component---src-pages-forget-password-en-js": () => import("./../../../src/pages/forget-password.en.js" /* webpackChunkName: "component---src-pages-forget-password-en-js" */),
  "component---src-pages-forget-password-ja-js": () => import("./../../../src/pages/forget-password.ja.js" /* webpackChunkName: "component---src-pages-forget-password-ja-js" */),
  "component---src-pages-forget-password-zh-js": () => import("./../../../src/pages/forget-password.zh.js" /* webpackChunkName: "component---src-pages-forget-password-zh-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-ja-js": () => import("./../../../src/pages/index.ja.js" /* webpackChunkName: "component---src-pages-index-ja-js" */),
  "component---src-pages-index-zh-js": () => import("./../../../src/pages/index.zh.js" /* webpackChunkName: "component---src-pages-index-zh-js" */),
  "component---src-pages-invitation-en-js": () => import("./../../../src/pages/invitation.en.js" /* webpackChunkName: "component---src-pages-invitation-en-js" */),
  "component---src-pages-invitation-ja-js": () => import("./../../../src/pages/invitation.ja.js" /* webpackChunkName: "component---src-pages-invitation-ja-js" */),
  "component---src-pages-invitation-zh-js": () => import("./../../../src/pages/invitation.zh.js" /* webpackChunkName: "component---src-pages-invitation-zh-js" */),
  "component---src-pages-login-en-js": () => import("./../../../src/pages/login.en.js" /* webpackChunkName: "component---src-pages-login-en-js" */),
  "component---src-pages-login-ja-js": () => import("./../../../src/pages/login.ja.js" /* webpackChunkName: "component---src-pages-login-ja-js" */),
  "component---src-pages-login-zh-js": () => import("./../../../src/pages/login.zh.js" /* webpackChunkName: "component---src-pages-login-zh-js" */),
  "component---src-pages-loyalty-update-en-js": () => import("./../../../src/pages/loyalty-update.en.js" /* webpackChunkName: "component---src-pages-loyalty-update-en-js" */),
  "component---src-pages-loyalty-update-ja-js": () => import("./../../../src/pages/loyalty-update.ja.js" /* webpackChunkName: "component---src-pages-loyalty-update-ja-js" */),
  "component---src-pages-loyalty-update-zh-js": () => import("./../../../src/pages/loyalty-update.zh.js" /* webpackChunkName: "component---src-pages-loyalty-update-zh-js" */),
  "component---src-pages-maintenance-en-js": () => import("./../../../src/pages/maintenance.en.js" /* webpackChunkName: "component---src-pages-maintenance-en-js" */),
  "component---src-pages-maintenance-ja-js": () => import("./../../../src/pages/maintenance.ja.js" /* webpackChunkName: "component---src-pages-maintenance-ja-js" */),
  "component---src-pages-maintenance-zh-js": () => import("./../../../src/pages/maintenance.zh.js" /* webpackChunkName: "component---src-pages-maintenance-zh-js" */),
  "component---src-pages-member-benefits-en-js": () => import("./../../../src/pages/member-benefits.en.js" /* webpackChunkName: "component---src-pages-member-benefits-en-js" */),
  "component---src-pages-member-benefits-ja-js": () => import("./../../../src/pages/member-benefits.ja.js" /* webpackChunkName: "component---src-pages-member-benefits-ja-js" */),
  "component---src-pages-member-benefits-zh-js": () => import("./../../../src/pages/member-benefits.zh.js" /* webpackChunkName: "component---src-pages-member-benefits-zh-js" */),
  "component---src-pages-member-changeprofile-en-js": () => import("./../../../src/pages/member/changeprofile.en.js" /* webpackChunkName: "component---src-pages-member-changeprofile-en-js" */),
  "component---src-pages-member-changeprofile-ja-js": () => import("./../../../src/pages/member/changeprofile.ja.js" /* webpackChunkName: "component---src-pages-member-changeprofile-ja-js" */),
  "component---src-pages-member-changeprofile-zh-js": () => import("./../../../src/pages/member/changeprofile.zh.js" /* webpackChunkName: "component---src-pages-member-changeprofile-zh-js" */),
  "component---src-pages-member-index-en-js": () => import("./../../../src/pages/member/index.en.js" /* webpackChunkName: "component---src-pages-member-index-en-js" */),
  "component---src-pages-member-index-ja-js": () => import("./../../../src/pages/member/index.ja.js" /* webpackChunkName: "component---src-pages-member-index-ja-js" */),
  "component---src-pages-member-index-zh-js": () => import("./../../../src/pages/member/index.zh.js" /* webpackChunkName: "component---src-pages-member-index-zh-js" */),
  "component---src-pages-member-redemption-en-js": () => import("./../../../src/pages/member/redemption.en.js" /* webpackChunkName: "component---src-pages-member-redemption-en-js" */),
  "component---src-pages-member-redemption-ja-js": () => import("./../../../src/pages/member/redemption.ja.js" /* webpackChunkName: "component---src-pages-member-redemption-ja-js" */),
  "component---src-pages-member-redemption-zh-js": () => import("./../../../src/pages/member/redemption.zh.js" /* webpackChunkName: "component---src-pages-member-redemption-zh-js" */),
  "component---src-pages-old-member-benefits-en-js": () => import("./../../../src/pages/old-member-benefits.en.js" /* webpackChunkName: "component---src-pages-old-member-benefits-en-js" */),
  "component---src-pages-old-member-benefits-ja-js": () => import("./../../../src/pages/old-member-benefits.ja.js" /* webpackChunkName: "component---src-pages-old-member-benefits-ja-js" */),
  "component---src-pages-old-member-benefits-zh-js": () => import("./../../../src/pages/old-member-benefits.zh.js" /* webpackChunkName: "component---src-pages-old-member-benefits-zh-js" */),
  "component---src-pages-old-terms-and-conditions-8-en-js": () => import("./../../../src/pages/old-terms-and-conditions-8.en.js" /* webpackChunkName: "component---src-pages-old-terms-and-conditions-8-en-js" */),
  "component---src-pages-old-terms-and-conditions-8-ja-js": () => import("./../../../src/pages/old-terms-and-conditions-8.ja.js" /* webpackChunkName: "component---src-pages-old-terms-and-conditions-8-ja-js" */),
  "component---src-pages-old-terms-and-conditions-8-zh-js": () => import("./../../../src/pages/old-terms-and-conditions-8.zh.js" /* webpackChunkName: "component---src-pages-old-terms-and-conditions-8-zh-js" */),
  "component---src-pages-partner-en-js": () => import("./../../../src/pages/partner.en.js" /* webpackChunkName: "component---src-pages-partner-en-js" */),
  "component---src-pages-partner-ja-js": () => import("./../../../src/pages/partner.ja.js" /* webpackChunkName: "component---src-pages-partner-ja-js" */),
  "component---src-pages-partner-zh-js": () => import("./../../../src/pages/partner.zh.js" /* webpackChunkName: "component---src-pages-partner-zh-js" */),
  "component---src-pages-pay-with-points-en-js": () => import("./../../../src/pages/pay-with-points.en.js" /* webpackChunkName: "component---src-pages-pay-with-points-en-js" */),
  "component---src-pages-pay-with-points-ja-js": () => import("./../../../src/pages/pay-with-points.ja.js" /* webpackChunkName: "component---src-pages-pay-with-points-ja-js" */),
  "component---src-pages-pay-with-points-zh-js": () => import("./../../../src/pages/pay-with-points.zh.js" /* webpackChunkName: "component---src-pages-pay-with-points-zh-js" */),
  "component---src-pages-preferred-hotels-resorts-en-js": () => import("./../../../src/pages/preferred-hotels-resorts.en.js" /* webpackChunkName: "component---src-pages-preferred-hotels-resorts-en-js" */),
  "component---src-pages-preferred-hotels-resorts-ja-js": () => import("./../../../src/pages/preferred-hotels-resorts.ja.js" /* webpackChunkName: "component---src-pages-preferred-hotels-resorts-ja-js" */),
  "component---src-pages-preferred-hotels-resorts-zh-js": () => import("./../../../src/pages/preferred-hotels-resorts.zh.js" /* webpackChunkName: "component---src-pages-preferred-hotels-resorts-zh-js" */),
  "component---src-pages-promotion-index-en-js": () => import("./../../../src/pages/promotion/index.en.js" /* webpackChunkName: "component---src-pages-promotion-index-en-js" */),
  "component---src-pages-promotion-index-ja-js": () => import("./../../../src/pages/promotion/index.ja.js" /* webpackChunkName: "component---src-pages-promotion-index-ja-js" */),
  "component---src-pages-promotion-index-zh-js": () => import("./../../../src/pages/promotion/index.zh.js" /* webpackChunkName: "component---src-pages-promotion-index-zh-js" */),
  "component---src-pages-reward-detail-en-js": () => import("./../../../src/pages/reward/detail.en.js" /* webpackChunkName: "component---src-pages-reward-detail-en-js" */),
  "component---src-pages-reward-detail-ja-js": () => import("./../../../src/pages/reward/detail.ja.js" /* webpackChunkName: "component---src-pages-reward-detail-ja-js" */),
  "component---src-pages-reward-detail-zh-js": () => import("./../../../src/pages/reward/detail.zh.js" /* webpackChunkName: "component---src-pages-reward-detail-zh-js" */),
  "component---src-pages-reward-index-en-js": () => import("./../../../src/pages/reward/index.en.js" /* webpackChunkName: "component---src-pages-reward-index-en-js" */),
  "component---src-pages-reward-index-ja-js": () => import("./../../../src/pages/reward/index.ja.js" /* webpackChunkName: "component---src-pages-reward-index-ja-js" */),
  "component---src-pages-reward-index-zh-js": () => import("./../../../src/pages/reward/index.zh.js" /* webpackChunkName: "component---src-pages-reward-index-zh-js" */),
  "component---src-pages-riverside-golf-club-en-js": () => import("./../../../src/pages/riverside-golf-club.en.js" /* webpackChunkName: "component---src-pages-riverside-golf-club-en-js" */),
  "component---src-pages-riverside-golf-club-ja-js": () => import("./../../../src/pages/riverside-golf-club.ja.js" /* webpackChunkName: "component---src-pages-riverside-golf-club-ja-js" */),
  "component---src-pages-riverside-golf-club-zh-js": () => import("./../../../src/pages/riverside-golf-club.zh.js" /* webpackChunkName: "component---src-pages-riverside-golf-club-zh-js" */),
  "component---src-pages-signup-en-js": () => import("./../../../src/pages/signup.en.js" /* webpackChunkName: "component---src-pages-signup-en-js" */),
  "component---src-pages-signup-ja-js": () => import("./../../../src/pages/signup.ja.js" /* webpackChunkName: "component---src-pages-signup-ja-js" */),
  "component---src-pages-signup-zh-js": () => import("./../../../src/pages/signup.zh.js" /* webpackChunkName: "component---src-pages-signup-zh-js" */),
  "component---src-pages-terms-and-conditions-8-en-js": () => import("./../../../src/pages/terms-and-conditions-8.en.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-8-en-js" */),
  "component---src-pages-terms-and-conditions-8-ja-js": () => import("./../../../src/pages/terms-and-conditions-8.ja.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-8-ja-js" */),
  "component---src-pages-terms-and-conditions-8-zh-js": () => import("./../../../src/pages/terms-and-conditions-8.zh.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-8-zh-js" */),
  "component---src-pages-unsub-en-js": () => import("./../../../src/pages/unsub.en.js" /* webpackChunkName: "component---src-pages-unsub-en-js" */),
  "component---src-pages-unsub-ja-js": () => import("./../../../src/pages/unsub.ja.js" /* webpackChunkName: "component---src-pages-unsub-ja-js" */),
  "component---src-pages-unsub-zh-js": () => import("./../../../src/pages/unsub.zh.js" /* webpackChunkName: "component---src-pages-unsub-zh-js" */),
  "component---src-pages-unsubscribe-en-js": () => import("./../../../src/pages/unsubscribe.en.js" /* webpackChunkName: "component---src-pages-unsubscribe-en-js" */),
  "component---src-pages-unsubscribe-ja-js": () => import("./../../../src/pages/unsubscribe.ja.js" /* webpackChunkName: "component---src-pages-unsubscribe-ja-js" */),
  "component---src-pages-unsubscribe-zh-js": () => import("./../../../src/pages/unsubscribe.zh.js" /* webpackChunkName: "component---src-pages-unsubscribe-zh-js" */),
  "component---src-pages-verification-success-en-js": () => import("./../../../src/pages/verification-success.en.js" /* webpackChunkName: "component---src-pages-verification-success-en-js" */),
  "component---src-pages-verification-success-ja-js": () => import("./../../../src/pages/verification-success.ja.js" /* webpackChunkName: "component---src-pages-verification-success-ja-js" */),
  "component---src-pages-verification-success-zh-js": () => import("./../../../src/pages/verification-success.zh.js" /* webpackChunkName: "component---src-pages-verification-success-zh-js" */),
  "component---src-pages-verify-account-en-js": () => import("./../../../src/pages/verify-account.en.js" /* webpackChunkName: "component---src-pages-verify-account-en-js" */),
  "component---src-pages-verify-account-ja-js": () => import("./../../../src/pages/verify-account.ja.js" /* webpackChunkName: "component---src-pages-verify-account-ja-js" */),
  "component---src-pages-verify-account-zh-js": () => import("./../../../src/pages/verify-account.zh.js" /* webpackChunkName: "component---src-pages-verify-account-zh-js" */),
  "component---src-templates-promotion-js": () => import("./../../../src/templates/promotion.js" /* webpackChunkName: "component---src-templates-promotion-js" */)
}

